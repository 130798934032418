/*@font-face {
  font-family: 'Avenir Next';
  src: local('Avenir Next'), url('./fonts/Avenir\ Next.ttc') format('truetype');
  font-weight: normal;
  font-style: normal;
}*/

body {
  font-family: 'Avenir Next', sans-serif;
  padding-top: 35px;
}

.main-content {
  padding: 20px;
  padding-top: 80px;
}

.main-content button, .main-content input, .main-content textarea, .login input, .login button {
  display: block;
  margin: 5px auto;
}

h1, h2, h3, h4, .PCenter, #documentForm, #myProfileCenter, .login {
  text-align: center;
}

.login-button-container {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2E2A29;
  color: white;
  text-decoration: none;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  margin-right: 30px;
}

.login-btn:hover {
  background-color: white;
  color: #450012;
}

.login-container {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login {
  border: 2px, solid, black;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  background-color: #f9f9f9;
}

#loginButton {
  margin-top: 20px;
}

table {
  width: 75%;
  margin: 5px auto;
  border-collapse: collapse;
  text-align: center;
  border: 2px solid #450012;
}

th, td {
  padding: 10px;
  text-align: center;
}

th {
  background-color: #450012;
  color: #FFFFFF;
}

tr:nth-child(odd) {
  background-color: #f2f2f2; /* Light grey odd rows */
}

tr:nth-child(even) {
  background-color: #FFFFFF; /* White even rows */
}

header {
  background-color: #450012;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.header-logo {
  max-height: 100px;
}

.dropdown {
  position: relative;
}

.nav-links {
  display: none;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  color: white;
}

.nav-links a {
  color: white;
  padding: 10px;
  text-decoration: none;
}

.nav-links a:hover {
  background-color: white;
  color: black;
}

.dropdown-toggle {
  background-color: #2E2A29;
  color: white;
  padding: 10px;
  font-size: 25px;
  border: none;
  cursor: pointer;
  margin-right: 40px;
  height: 100%;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 161%;
  right: 10px;
  background-color: #450012;
  border: 1px solid #450012;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  min-width: 150px;
}

.dropdown-menu a {
  padding: 10px;
  text-decoration: none;
  color: white;
}

.dropdown-menu a:hover {
  background-color: white;
  color: black;
}

.admin-sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #450012;
  color: white;
  padding-top: 40px;
}

.admin-nav ul {
  list-style-type: none;
  padding-left: 20px;
}

.admin-nav li {
  margin: 15px 0;
  font-size: 14px;
}

.admin-nav a {
  color: white;
  text-decoration: none;
}

.admin-nav a:hover {
  text-decoration: underline;
}

.admin-page {
  margin-left: 250px;
}

.fcar-text-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
}

.fcar-text-container label {
  font-weight: bold;
}

.fcar-text-container p {
  text-align: center;
  margin-top: 5px;
}

.document-type-container {
  margin-bottom: 20px;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.image-container {
  position: relative;
  display: inline-block;
  margin: 10px;
}

.image-container .document-hover-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.homeIMG {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-container:hover .document-hover-actions {
  opacity: 1;
}

.document-item {
  max-width: 150px;
  text-align: center;
}

.document-item img {
  display: block;
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.download-button,
.delete-button {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.2s ease;
}

.download-button:hover,
.delete-button:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.8);
}

select {
  display: block;
  margin: 0 auto;
}

/*Responsive design*/

/*Tiny devices*/
@media (max-width: 400px) {
  .header-logo {
    max-height: 40px;
  }
  .main-content {
    padding-top: 40px;
  }

  .dropdown-toggle {
    background-color: #2E2A29;
    color: white;
    padding: 5px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    margin-right: 30px;
    height: 100%;
  }
  
  .dropdown-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 145%;
    right: 10px;
    background-color: #450012;
    border: 1px solid #450012;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    min-width: 100px;
    font-size: 8px;
  }

  body h1, body h2, body h3, body h4{
    font-size: 12px;
  }
  body p, li{
    font-size: 8px;
  }

  table {
    width: 95%;
    margin: 3px auto;
    border-collapse: collapse;
    text-align: center;
    font-size: 8px;
    box-sizing: border-box;
  }

  th {
    padding: 2px;
  }
  
  td {
    padding: 1px;
  }

  button {
    padding: 1px;
    font-size: 8px;
  }
  .optional-column {
    display: none;
  }
  .smallInput {
    width: 15px;
  }
  /* Hide spinners in Chrome, Safari, Edge, and Opera */
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  /* Hide spinners in Firefox */
  input[type="number"] {
      -moz-appearance: textfield;
  }

  label {
    font-size: 10px;
  }

  input[type="file"] {
    width: 125px;
    font-size: 8px;
  }
  input {
    font-size: 12px;
  }

  textarea {
    font-size: 8px;
    width: 90%;
    height: 50px;
  }

  .document-item {
    max-width: 150px;
    text-align: center;
    margin: 10px;
  }

  .admin-sidebar {
    width: 40px;
    padding-top: 45px;
  }
  
  .admin-nav ul {
    padding-left: 5px;
  }
  
  .admin-nav li {
    margin: 5px 0;
    font-size: 8px;
  }
  
  .admin-page {
    margin-left: 25px;
  }
}

/*Small devices*/
@media (min-width: 401px) and (max-width: 768px) {
  .header-logo {
    max-height: 60px;
  }

  .main-content {
    padding-top: 60px;
  }

  .dropdown-toggle {
    background-color: #2E2A29;
    color: white;
    padding: 7px;
    font-size: 18px;
    border: none;
    cursor: pointer;
    margin-right: 30px;
    height: 90%;
  }
  
  .dropdown-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 155%;
    right: 10px;
    background-color: #450012;
    border: 1px solid #450012;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    min-width: 100px;
    font-size: 8px;
  }

  body h1, body h2, body h3, body h4{
    font-size: 14px;
  }
  body p, li{
    font-size: 10px;
  }

  table {
    width: 95%;
    margin: 3px auto;
    border-collapse: collapse;
    text-align: center;
    font-size: 10px;
    box-sizing: border-box;
  }

  th {
    font-size: 10px;
    padding: 2px;
  }
  
  td {
    padding: 1px;
  }
  .optional-column {
    display: none;
  }

  button {
    padding: 1px;
    font-size: 10px;
  }
  .smallInput {
    width: 15px;
  }
  /* Hide spinners in Chrome, Safari, Edge, and Opera */
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  /* Hide spinners in Firefox */
  input[type="number"] {
      -moz-appearance: textfield;
  }

  label {
    font-size: 12px;
  }

  input[type="file"] {
    font-size: 10px;
  }
  input {
    font-size: 12px;
  }

  textarea {
    font-size: 10px;
    width: 90%;
    height: 50px;
  }

  .document-item {
    max-width: 250px;
    text-align: center;
    margin: 10px;
  }

  .admin-sidebar {
    width: 60px;
    padding-top: 65px;
  }
  
  .admin-nav ul {
    padding-left: 5px;
  }
  
  .admin-nav li {
    margin: 10px 0;
    font-size: 12px;
  }
  
  .admin-page {
    margin-left: 60px;
  }
}

/*Medium devices*/
@media (min-width: 769px) and (max-width: 992px) {

  .header-logo {
    max-height: 80px;
  }

  .main-content {
    padding-top: 80px;
  }

  .dropdown-toggle {
    background-color: #2E2A29;
    color: white;
    padding: 10px;
    font-size: 20px;
    border: none;
    cursor: pointer;
    margin-right: 40px;
    height: 100%;
  }
  
  .dropdown-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 155%;
    right: 10px;
    background-color: #450012;
    border: 1px solid #450012;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    min-width: 150px;
  }

  body h1, body h2, body h3, body h4{
    font-size: 16px;
  }
  body p, li{
    font-size: 12px;
  }

  table {
    width: 85%;
    margin: 4px auto;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    box-sizing: border-box;
  }

  th {
    font-size: 14px;
    padding: 4px;
  }
  
  td {
    padding: 2px;
  }

  button {
    padding: 2px;
    font-size: 12px;
  }
  .smallInput {
    width: 40px;
  }

  label {
    font-size: 14px;
  }

  input[type="file"] {
    font-size: 12px;
  }
  input {
    font-size: 14px;
  }

  textarea {
    font-size: 10px;
    width: 80%;
    height: 100px;
  }

  .document-item {
    max-width: 300px;
    text-align: center;
    margin: 10px;
  }

  .admin-sidebar {
    width: 100px;
    padding-top: 85px;
  }
  
  .admin-nav ul {
    padding-left: 20px;
  }
  
  .admin-nav li {
    margin: 10px 0;
    font-size: 14px;
  }
  
  .admin-page {
    margin-left: 100px;
  }
}

/*Large devices*/
@media (min-width: 993px) and (max-width: 1200px) {
  body h1{
    font-size: 20px;
  }
  body h2 {
    font-size: 18px;
  }
  body h3 {
    font-size: 16px;
  }
  body h4 {
    font-size: 14px;
  }
  body p, li{
    font-size: 14px;
  }

  table {
    width: 85%;
    margin: 5px auto;
    border-collapse: collapse;
    text-align: center;
    font-size: 14px;
    box-sizing: border-box;
  }

  th {
    font-size: 16px;
    padding: 4px;
  }
  
  td {
    padding: 2px;
  }

  button {
    padding: 2px;
    font-size: 12px;
  }
  .smallInput {
    width: 60px;
  }

  label {
    font-size: 14px;
  }

  input[type="file"] {
    font-size: 12px;
  }
  input {
    font-size: 14px;
  }

  textarea {
    font-size: 12px;
    width: 80%;
    height: 100px;
  }

  .document-item {
    max-width: 400px;
    text-align: center;
    margin: 10px;
  }

  .admin-sidebar {
    padding-top: 105px;
  }
}

/*Extra large devices*/
@media (min-width: 1201px) and (max-width: 1600px) {
  .header-logo {
    max-height: 120px;
  }

  .main-content {
    padding-top: 120px;
  }
  .admin-sidebar {
    padding-top: 125px;
  }
  body h1{
    font-size: 22px;
  }
  body h2 {
    font-size: 20px;
  }
  body h3 {
    font-size: 18px;
  }
  body h4 {
    font-size: 16px;
  }
  body p, li{
    font-size: 16px;
  }

  table {
    width: 85%;
    margin: 5px auto;
    border-collapse: collapse;
    text-align: center;
    font-size: 16px;
    box-sizing: border-box;
  }

  th {
    font-size: 18px;
    padding: 4px;
  }
  
  td {
    padding: 2px;
  }

  button {
    padding: 2px;
    font-size: 12px;
  }
  .smallInput {
    width: 60px;
  }

  label {
    font-size: 16px;
  }

  input[type="file"] {
    font-size: 12px;
  }
  input {
    font-size: 14px;
  }

  textarea {
    font-size: 12px;
    width: 80%;
    height: 100px;
  }
  .nav-links {
    display: inline-flex;
  }

  .nav-links a{
    margin-left: 0px;
    padding: 5px;
  }

  .dropdown-toggle {
    display: none;
  }

  .document-item {
    max-width: 550px;
    text-align: center;
    margin: 10px;
  }
}

/*Huge devices*/
@media (min-width: 1601px) {
  .header-logo {
    max-height: 140px;
  }

  .main-content {
    padding-top: 140px;
  }
  .admin-sidebar {
    padding-top: 145px;
  }
  body h1{
    font-size: 24px;
  }
  body h2 {
    font-size: 22px;
  }
  body h3 {
    font-size: 20px;
  }
  body h4 {
    font-size: 18px;
  }
  body p, li{
    font-size: 16px;
  }

  table {
    width: 80%;
    margin: 5px auto;
    border-collapse: collapse;
    text-align: center;
    font-size: 16px;
    box-sizing: border-box;
  }

  th {
    font-size: 18px;
    padding: 4px;
  }
  
  td {
    padding: 2px;
  }

  button {
    padding: 2px;
    font-size: 12px;
  }
  .smallInput {
    width: 100px;
  }

  label {
    font-size: 20px;
  }

  input[type="file"] {
    font-size: 12px;
  }
  input {
    font-size: 14px;
  }

  textarea {
    font-size: 14px;
    width: 80%;
    height: 150px;
  }

  .document-item {
    max-width: 800px;
    text-align: center;
    margin: 10px;
  }

  .nav-links {
    display: inline-flex;
  }

  .dropdown-toggle {
    display: none;
  }
  .nav-links a {
    padding: 10px;
    font-size: 21px;
  }
}